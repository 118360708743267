import React from "react"

export const data = {
  title: "FAQ",
  rows: [
    {
      title: <b>Was ist ein Probelektorat? </b>,
      content: (
        <span>
          Das Probelektorat ist ein kostenloser Service von mir, der dir die
          Möglichkeit gibt, dich mit meinem Lektoratsprozess vertraut zu machen:
          Noch bevor du mir einen kostenpflichtigen Auftrag erteilst, bekommst
          du auf Wunsch 3 korrigierte und kommentierte Seiten deines Textes
          sowie einen detaillierten Kostenvoranschlag und einen genauen Zeitplan
          für die weitere Korrektur zugeschickt. Anhand dieser Informationen
          kannst du dich anschließend ganz frei für oder gegen einen
          Lektoratsauftrag entscheiden.
          <br />
          <br />
          Von dem Probelektorat profitieren wir beide: Es gibt mir die
          Möglichkeit, den individuellen Korrekturaufwand deiner Arbeit zu
          bestimmen und dir basierend darauf einen verbindlichen Zeitplan und
          fairen Kostenvoranschlag zu präsentieren. Der Aufwand und die Dauer
          eines intensiven Lektorats ist von der Zeichen- bzw. Seitenanzahl
          deines Textes, der Fehlerquote, deinem Schreibstil sowie meiner
          zeitlichen Verfügbarkeit abhängig und kann nicht in jedem Fall auf die
          Stunde genau vorausbestimmt werden. Daher behalte ich es mir vor, die
          Dauer eines Lektorates im Zweifelsfall als Zeitspanne anzugeben
          (hiervon ausgenommen ist die 24-Stunden-Korrektur).
          <br />
          <br />
          Bist du mit dem Probelektorat, dem aufgestellten Zeitplan und dem
          Kostenvoranschlag zufrieden, kannst du einen verbindlichen
          Lektoratsauftrag erteilen, indem du mein Angebot via E-Mail bestätigst
          und eine Anzahlung von 50% des festgelegten Preises entrichtest. Damit
          sichere ich dir zu, deine Arbeit innerhalb des abgemachten Zeitrahmens
          zu lektorieren. Die übrigen 50% werden am Ende unserer Zusammenarbeit
          bezahlt, sobald du mit der finalen Version deines korrigierten Textes
          zufrieden bist und keine Fragen oder Wünsche von dir mehr offen sind.
        </span>
      ),
    },
    {
      title: <b>Muss ich das Probelektorat in Anspruch nehmen?</b>,
      content: (
        <span>
          Nein, das Probelektorat ist ein kostenloses Serviceangebot von mir,
          das du nicht annehmen musst. Allerdings gibt es dir die Chance, dich
          mit meinem Lektoratsprozess vertraut zu machen und anhand der von mir
          korrigierten Textseiten zu entscheiden, ob du meine Hilfe in Anspruch
          nehmen willst. Wenn du kein Interesse an einem Probelektorat hast,
          kann ich dir auch direkt einen Kostenvoranschlag und Zeitplan
          aufstellen, ohne deinen Text vorher korrigiert zu haben. Alles, was
          ich hierfür benötige, ist ein Ausschnitt aus deinem Text und
          Informationen zur Seiten- und Zeichenanzahl, um mir ein Bild von dem
          Lektoratsaufwand machen zu können.
        </span>
      ),
    },
    {
      title: <b>Was ist ein Korrektorat?</b>,
      content: (
        <div>
          Bei einem Korrektorat wird ein Text auf korrekte Rechtschreibung,
          Grammatik und Interpunktion hin untersucht. Ggf. werden auch kleine
          Änderung am Ausdruck und der Syntax vorgenommen, um den Lesefluss zu
          verbessern, sowie grobe inhaltliche Fehler berichtigt. In der Regel
          greift ein Korrektorat jedoch nicht in den Inhalt eines Textes ein,
          sondern optimiert lediglich die „äußere Form“. Für Texte, die eine
          intensive Korrektur des Stils und der inhaltlichen Kohärenz benötigen,
          empfehle ich daher das Standard- oder „Zweitblick“-Lektorat.
          <br />
          <br />
          Ein intensives Korrektorat von mir enthält:
          <ul className="list-disc ml-6">
            <li>mehrmaliges Lesen und Überarbeiten des Textes</li>
            <li>
              sorgfältige Korrektur von Interpunktion, Rechtschreibung,
              Grammatik
            </li>
            <li>Ggf. leichte Glättung der Syntax und Anpassung des Stils</li>
            <li>Ggf. Korrektur grober inhaltlicher Fehler</li>
            <li>
              Korrektur der Fußnoten: Zeichensetzung, Rechtschreibung,
              Grammatik, einheitliche Zitierweise
            </li>
            <li>
              Layout-Check: einheitliche Abstände, Seitenränder, Einrückungen,
              Aufzählungen etc.
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: (
        <b>
          Wie sieht der finale Text aus, den ich am Ende des Korrektorats
          geschickt bekomme?
        </b>
      ),
      content: (
        <span>
          Am Ende des Standard-Korrektorats sowie des
          Express-24-Stunden-Korrektorats bekommst du eine korrigierte
          Word-Datei deines Textes von mir zugeschickt, in der alle
          vorgenommenen Änderungen im Nachverfolgungsmodus oder in Form von
          eingefügten Kommentaren sichtbar sind. Somit kannst du meinen
          Korrekturprozess genau nachvollziehen und die gemachten Änderungen
          nach eigenem Ermessen annehmen oder ablehnen. Auf Wunsch schicke ich
          dir zusätzlich eine Word-Datei zu, in der bereits alle Änderungen
          angenommen wurden und nur noch die von mir eingefügten
          Anmerkungen/Kommentare in deinem Text sichtbar sind.
        </span>
      ),
    },
    {
      title: <b>Was ist ein Lektorat?</b>,
      content: (
        <span>
          Das Lektorat eines Textes enthält die einzelnen Schritte des
          Korrektorats (Korrektur der Rechtschreibung, Grammatik und
          Interpunktion; leichte Glättung der Syntax und des Stils), geht in der
          Überarbeitung des Textes jedoch weit über eine Korrektur hinaus: Ein
          Lektorat enthält zusätzlich eine intensive Prüfung des Textes auf
          inhaltliche Kohärenz und schlüssige Argumentation (auf den sogenannten
          „roten Faden“) sowie eine umfangreiche Optimierung des Stils.
          <br />
          <br />
          Ein intensives Lektorat von mir enthält:
          <ul className="list-disc ml-6">
            <li>mehrmaliges Lesen und Überarbeiten des Textes</li>
            <li>
              sorgfältige Korrektur von Interpunktion, Rechtschreibung,
              Grammatik
            </li>
            <li>Glättung der Syntax</li>
            <li>Anpassung des Stils</li>
            <li>
              Prüfung von inhaltlicher Kohärenz (schlüssige Argumentation, keine
              Wiederholungen, keine inhaltlichen Widersprüche etc.)
            </li>
            <li>
              Korrektur der Fußnoten: Interpunktion, Rechtschreibung, Grammatik,
              einheitliche Zitierweise
            </li>
            <li>
              Layout-Check: einheitliche Abstände, Seitenränder, Einrückungen,
              Aufzählungen etc.
            </li>
          </ul>
          <br />
          <br />
          Auf Wunsch prüfe ich deinen Text auch auf eine gendergerechte
          Schreibweise.
          <br />
          <br />
          <b>
            Bitte beachte, dass ich bei meinem Lektorat zwar prüfe, ob das, was
            du geschrieben hast, richtig, plausibel und ohne Widersprüche ist
            und deine Argumentation logisch aufeinander aufbaut – allerdings
            kann ich nicht beurteilen, ob die (wissenschaftlichen) Aussagen, die
            du triffst, der Wahrheit entsprechen. Ebenso wenig kann ich eine
            Plagiatsprüfung vornehmen oder die von dir verwendeten Zitate auf
            Vollständigkeit und/oder Richtigkeit überprüfen.
          </b>
        </span>
      ),
    },
    {
      title: (
        <b>
          Wie sieht der finale Text aus, den ich am Ende des Lektorats geschickt
          bekomme?
        </b>
      ),
      content: (
        <span>
          Am Ende des Standard-Lektorats bekommst du zwei Versionen deines
          Textes sowie ein schriftliches Feedback als Word-Dateien von mir
          geschickt: Die erste korrigierte Datei enthält den Text mit allen von
          mir gemachten Korrekturen, Änderungen und Anmerkungen, die mithilfe
          des Nachverfolgungs-Modus und eingefügten Kommentaren in Word sichtbar
          gemacht wurden und nun von dir manuell angenommen oder abgelehnt
          werden können. In der zweiten korrigierten Version des Textes wurden
          meine Korrekturen (Zeichensetzung, Rechtschreibung und Grammatik) und
          stilistischen Änderungen im Text bereits angenommen und nur noch meine
          Anmerkungen und Kommentare z.B. in Bezug auf Kohärenz oder inhaltliche
          Unklarheiten sind noch neben dem Text zu sehen. Es steht dir somit
          immer vollkommen frei, welche meiner Änderungen du umsetzen und mit
          welcher Version des Textes du weiterarbeiten willst.
          <br />
          <br />
          Das Ziel meines Lektorates ist es, deinen Text zu korrigieren und in
          eine fehlerfreie, geglättete Version zu bringen – nicht, ihn komplett
          umzuschreiben oder deinen individuellen Stil zu verändern! Daher liegt
          es nach dem Lektorat auch in deiner Hand, zu entscheiden, welche
          meiner Änderungen du in deinen Text übernehmen und welche du ablehnen
          möchtest. Da am Ende dein Name unter dem Text steht, soll es auch
          unbedingt dein Text bleiben!
          <br />
          <br />
          Hast du kein Standard-Lektorat, sondern ein „Zweitblick“ - oder
          360-Grad-Lektorat gebucht, folgt nun ein zweiter gründlicher
          Lektoratsdurchgang: Nachdem du die Änderungen in der von mir
          korrigierte Version deines Textes nach eigenem Ermessen übernommen
          (oder abgelehnt) hast, schickst du mir die fertig bearbeitete Datei
          für den zweiten und letzten Lektoratsdurchgang zurück. Hierbei
          kontrolliere ich nochmals die Rechtschreibung, Zeichensetzung und
          Grammatik sowie den Stil und die Syntax deines Textes. Außerdem stelle
          ich sicher, dass sich bei deiner Überarbeitung des korrigierten Textes
          &nbsp;keine neuen Fehler eingeschlichen haben. Anschließend schicke
          ich dir den erneut korrigierten Text als Word-Datei zurück, in der
          alle gemachten Änderungen sowie Anmerkungen sichtbar sind.
          &nbsp;Selbstverständlich kannst du dich bei Rückfragen oder
          Unklarheiten auch nach dem zweiten Lektoratsdurchgang gerne jederzeit
          an mich wenden . Bist du zufrieden mit der finalen Version deines
          Textes, so ist an dieser Stelle unsere Zusammenarbeit beendet und du
          kannst dich über einen intensiv und sorgfältig korrigierten Text
          freuen.
        </span>
      ),
    },
    {
      title: (
        <b>Wie viel Zeit muss ich für ein Korrektorat/Lektorat einplanen?</b>
      ),
      content: (
        <span>
          Das kommt ganz auf die Seiten- bzw. Zeichenanzahl deines Textes an und
          darauf, wie viel darin zu korrigieren ist. Daher verschaffe ich mir
          immer erstmal einen Überblick über deinen Text und den
          Korrekturaufwand, den er benötigt, bevor ich dir einen
          Kostenvoranschlag und genauen Zeitplan schicke. In der Regel nimmt ein
          Lektorat mehr Zeit in Anspruch als eine Korrektur. Für ein
          <b> „Zweitblick“</b>- bzw. <b>360-Grad-Lektorat</b> musst du ebenfalls
          eine längere Bearbeitungszeit einplanen, da ich deinen Text zweimal
          lektoriere.
        </span>
      ),
    },
    {
      title: (
        <b>
          Ich habe eine dringende Deadline und brauche eine schnelle Korrektur
          meines Textes
        </b>
      ),
      content: (
        <span>
          Kein Problem! Für genau solche Fälle biete ich ein
          <b> Express-24-Stunden-Korrektorat</b> für Texte bis zu 40 Seiten an.
          Hierbei korrigierte ich die Interpunktion, Grammatik und
          Rechtschreibung deines Textes und deiner Fußnoten. Wenn nötig, nehme
          ich auch kleinere Änderungen in Bezug auf Stil und Syntax vor, um
          deinen Text zu glätten und den Lesefluss zu erhöhen. Ein Layout-Check
          ist ebenfalls im 24-Stunden-Korrektorat enthalten.
          <br />
          Leider kann ich auch dringliche Anfragen für ein
          <b> Express-24-Stunden-Korrektorat</b> nur dann annehmen, wenn mir die
          zeitliche Kapazität dafür gegeben ist. Für den Fall, dass ich bereits
          komplett ausgebucht bin und deinen Auftrag nicht annehmen kann,
          empfehle ich dir gerne einen anderen Lektorats- oder Korrekturservice.
        </span>
      ),
    },
    {
      title: <b>Welches Package ist für meinen Text geeignet?</b>,
      content: (
        <span>
          Ebenso wie nicht jeder Stil für jede Textsorte geeignet ist (eine
          wissenschaftliche Hausarbeit erfordert z.B. einen anderen Stil als
          etwa ein Werbetext oder Buchmanuskript), bieten sich auch verschiedene
          meiner Packages für unterschiedliche Textsorten an: In der Regel
          empfehle ich das <b>Standard-Lektorat</b> für Haus- oder
          Seminararbeiten, Webseitentexte, Werbetexte, Flyer oder Broschüren.
          <br />
          Für längere, komplexere Texte wie Manuskripte (Belletristik, Gedicht-
          oder Kurzgeschichtenbände, Kinder- oder Sachbücher, wissenschaftliche
          Abhandlungen), Bachelor- und Masterarbeiten, Dissertationen oder
          wissenschaftliche Fachartikel empfiehlt sich das
          <b>&nbsp;„Zweitblick“</b>- oder <b>360-Grad-Lektorat</b>. Wenn es mal
          sehr schnell gehen muss, weil die Deadline vor der Tür steht, biete
          ich eine <b>Express-24-Stunden-Korrektur</b> an.
          <br />
          <br />
          Falls du dir noch nicht sicher bist, wie viel Korrektur dein Text
          benötigt, dann schicke mir doch einfach eine unverbindliche Anfrage
          über das Kontaktformular. Dann können wir gemeinsam ein passendes
          Format finden, um deine Arbeit zu verbessern.
        </span>
      ),
    },
    {
      title: <b>Ich habe noch Frage zu meinem Text</b>,
      content: (
        <span>
          Kein Problem, du kannst mich jederzeit über das Kontaktformular oder
          unter{" "}
          <a
            href="mailto:lea-gremm@zweitblick-lektorat.at?subject=Zweitblick-Lektorat%20Anfrage"
            className="text-blue-600 visited:text-purple-600"
          >
            lea-gremm@zweitblick-lektorat.at
          </a>{" "}
          kontaktieren.
        </span>
      ),
    },
  ],
}

export const styles = {
  bgColor: "transparent",
  titleTextColor: "#694E4E",
  rowTitleColor: "#161616",
  rowContentPaddingBottom: "20px",
  // rowContentColor: 'grey',
  // arrowColor: "red",
}

export const config = {
  // animate: true,
  // arrowIcon: "V",
  // tabFocus: true
}
