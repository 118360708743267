import * as React from "react"

// Custom Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import Button from "../components/button"

// Hooks
import { useBreakpoint } from "../utils/hooks"

// Third-Party
import Faq from "react-faq-component"

// FAQ
import { data, config, styles } from "../components/faq/faq-config"

// Images
import ItemIcon from "../assets/images/label_important.svg"
import TypeWriter from "../assets/images/typewriter.png"
import HelpIcon from "../assets/images/help-icon.svg"

const toggleTooltip = (e, tooltipId, tooltipText) => {
  if (!document) return
  let tooltip = document.getElementById(tooltipId)
  if (tooltip) tooltip.remove()
  else {
    tooltip = document.createElement("div")
    tooltip.id = tooltipId
    tooltip.className =
      "border border-black rounded-md p-2 bg-slate-300 absolute w-[200px] text-sm"
    tooltip.style.left = `${e.target.offsetLeft + 25}px`
    const textnode = document.createTextNode(tooltipText)
    tooltip.appendChild(textnode)
    e.target.parentElement.appendChild(tooltip)
  }
}

const Preise = () => {
  const { width } = useBreakpoint()
  return (
    <Layout>
      <Seo title="Preise" />
      <div className="container mx-auto max-w-6xl mt-12 ">
        <div className="grid sm:grid-cols-2 gap-20">
          <div className="mx-4 sm:mx-0 ">
            <h1 className="mb-6">Preise</h1>
            <div className="text-lg">
              Der Preis für eine Korrektur oder ein Lektorat deines Textes hängt
              von der <b> Zeichen- bzw. Seitenanzahl</b> sowie dem jeweiligen
              <b> Korrekturaufwand</b> ab. Daher kann ich dir erst einen genauen
              Kostenvoranschlag machen, nachdem ich mir einen Überblick über
              deinen Text verschafft habe.
              <br />
              <br />
              <br />
              Darüber hinaus wird ein Lektorat/Korrektorat stets per Normseite
              berechnet. Hier findest du die Preisübersicht:
            </div>
          </div>
          {width > 640 && (
            <img src={TypeWriter} width={500} className="mt-10" />
          )}
        </div>

        <div className="mx-4 sm:mx-auto">
          <span>
            <div className="mt-20 mb-20 grid sm:grid-cols-2 grid-rows-1 gap-24 max-w-4xl mx-auto">
              <div className="h-[410px] border-2 rounded-md p-5 border-zb-brown flex flex-col justify-between bg-white">
                <div>
                  <h4 className="mb-6 font-bold">Korrektorat</h4>
                  <span className="flex items-center mb-6">
                    <span className="text-4xl font-bold  mr-2 ">ab 3€</span>
                    <span className="text-lg flex items-start">
                      /Normseite{" "}
                      <img
                        src={HelpIcon}
                        width={15}
                        className="text-slate-500"
                        onMouseEnter={e =>
                          toggleTooltip(
                            e,
                            "normseite",
                            "Eine Normseite entspricht 1800 Zeichen inklusive Leerzeichen."
                          )
                        }
                        onMouseLeave={e => toggleTooltip(e, "normseite")}
                        onKeyDown={() => {}}
                        onClick={() => {}}
                        alt=""
                      ></img>
                    </span>
                  </span>
                  <p className="text-lg mb-4 font-bold">Angebotsoptionen:</p>
                  <div className="text-lg italic flex ">
                    <img src={ItemIcon} width={30} className="mr-3" alt="" />
                    Standard-Korrektur
                  </div>
                  <div className="text-lg italic flex ">
                    <img src={ItemIcon} width={30} className="mr-3" alt="" />
                    Express 24-Stunden-Korrektur
                  </div>
                </div>
                <div className="flex justify-start">
                  <Button small>Kontaktiere mich</Button>
                </div>
              </div>
              <div className="h-[410px] border-2 rounded-md p-5 border-zb-brown flex flex-col justify-between bg-white">
                <div>
                  <h4 className="mb-6 font-bold">Lektorat</h4>
                  <span className="flex items-center mb-6">
                    <span className="text-4xl font-bold  mr-2 ">ab 4€</span>
                    <span className="text-lg flex items-start">
                      /Normseite{" "}
                      <img
                        src={HelpIcon}
                        width={15}
                        className="text-slate-500"
                        onMouseEnter={e =>
                          toggleTooltip(
                            e,
                            "normseite",
                            "Eine Normseite entspricht 1800 Zeichen inklusive Leerzeichen."
                          )
                        }
                        onMouseLeave={e => toggleTooltip(e, "normseite")}
                        onKeyDown={() => {}}
                        alt=""
                      ></img>
                    </span>
                  </span>
                  <p className="text-lg mb-4 font-bold">Angebotsoptionen:</p>
                  <div className="text-lg italic flex ">
                    <img src={ItemIcon} width={30} className="mr-3" alt="" />
                    Standard-Lektorat
                  </div>
                  <div className="text-lg italic flex ">
                    <img src={ItemIcon} width={30} className="mr-3" alt="" />
                    „Zweitblick“-Lektorat
                  </div>
                  <div className="text-lg italic flex ">
                    <img src={ItemIcon} width={30} className="mr-3" alt="" />
                    360-Grad-Lektorat
                  </div>
                  <div className="text-lg italic flex ">
                    <img src={ItemIcon} width={30} className="mr-3" alt="" />
                    Individuelles Lektorat
                  </div>
                </div>
                <div className="flex justify-start">
                  <Button small>Kontaktiere mich</Button>
                </div>
              </div>
            </div>
            <div className="border-4 rounded-md border-white p-4 bg-[#FFEFEF] text-center">
              <p className="text-md mb-2 ">
                <b>Studierendenrabatt</b>
              </p>
              Für Studierende biete ich außerdem einen Rabatt von -1 Euro /
              Normseite (nach Vorlage eines gültigen Studentenausweises) an.
              <br />
              Bitte beachte: Der Studierendenrabatt gilt nicht für eine
              24-Stunden-Korrektur!
            </div>
          </span>
        </div>
        <br />
        <br />
        <br />

        <div id="faq" className="mx-4 sm:mx-auto">
          <Faq data={data} styles={styles} config={config} />
        </div>

        <br />
        <br />
        <br />
      </div>
    </Layout>
  )
}

export default Preise
